.ag-theme-qa-dashboard-light {
  --ag-header-height: 60px;
  --ag-odd-row-background-color: #eef4fc;
  --ag-header-foreground-color: lightgrey;
  --ag-header-background-color: #6B5ECC;
  --ag-grid-size: 5px;
  --ag-list-item-height: 15px;
}

.ag-theme-qa-dashboard-dark {
  --ag-header-height: 60px;
  --ag-odd-row-background-color: #eef4fc;
  --ag-header-foreground-color: lightgrey;
  --ag-header-background-color: #242E4E;
  --ag-grid-size: 5px;
  --ag-list-item-height: 15px;
}

.ag-theme-qa-dashboard-legacy {
  --ag-header-height: 60px;
  --ag-odd-row-background-color: #eef4fc;
  --ag-header-foreground-color: lightgrey;
  --ag-header-background-color: #30455c;
  --ag-grid-size: 5px;
  --ag-list-item-height: 15px;
}

.ag-theme-qa-dashboard .ag-root-wrapper {
  border-radius: 10px;
}

.ag-theme-qa-dashboard .ag-header-cell {
  font-size: 14px;
  row-gap: 20px;
}

.ag-theme-qa-dashboard .ag-cell {
  font-size: 15px;
  word-break: break-word;
  line-height: 1.5em;
}

.ag-theme-qa-dashboard .ag-text-field-input-wrapper {
  color: black;
}

.ag-theme-qa-dashboard .ag-header-cell-label {
  justify-content: center;
}

.cell-fail {
  text-align: center;
  font-weight: bold;
  background-color: #bc1f14;
  color: white
}


.cell-stable {
  text-align: center;
  font-weight: bold;
  background-color: #4caf50;
  color: white
}

.cell-pass {
  text-align: center;
  font-weight: bold;
  background-color: #034904;
  color: white
}

.cell-negative {
  color: red;
}

.header-text-center {
  .ag-header-cell-label {
    justify-content: center;
  }
}