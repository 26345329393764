.graphiql-explorer-wrap {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.graphiql-explorer-operation-title-bar {
  padding-bottom: 4px;
  color: hsl(var(--color-primary));
}

.graphiql-explorer-title {
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-medium);
}

.graphiql-explorer-root {
  font-family: var(--font-family-mono);
  font-size: var(--font-size-body);
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.graphiql-explorer-root-tree {
  flex-grow: 1;
  overflow: hidden;
  padding-left: var(--px-8);
}

.graphiql-explorer-field{
  border-bottom: none;
  margin-bottom: 0;
  padding: 0;
  overflow: hidden;
}
.graphiql-explorer-field-name{
  color: hsl(var(--color-info));
}

.graphiql-explorer-arg {
  cursor: pointer;
  min-height: 16px;
  -webkit-user-select: none;
  user-select: none;
}
.graphiql-explorer-arg-name{
  color: hsl(var(--color-tertiary));
}

.graphiql-explorer-variable-action{
  color: hsl(var(--color-secondary)) !important;
}
.graphiql-explorer-input-variable{
  color: hsl(var(--color-secondary)) !important;
}
.graphiql-explorer-input-enum{
    color: hsl(var(--color-secondary)) !important;
}
.graphiql-explorer-input-boolean{
  color: hsl(var(--color-success)) !important;
}

.graphiql-explorer-input-number, .graphiql-explorer-input-number input{
  color: hsl(var(--color-success)) !important;
}

.graphiql-explorer-input-string, .graphiql-explorer-input-string input{
  color: hsl(var(--color-warning)) !important;
}

.graphiql-explorer-root input {
  background: hsl(var(--color-base));
  border: none;
  border-bottom: 1px solid #666;
  outline: none;
  width: 120px;
}

.graphiql-explorer-node{
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.graphiql-explorer-toolbar-button {
  color: hsla(var(--color-neutral), var(--alpha-secondary, 0.6));
  cursor: pointer;
  font-size: 1em;
  padding: 0;
  background-color: transparent;
  border: none;
  margin: 5px 0;
  height: 40px;
  width: 100%;
  display: block;
  max-width: none;
}

.graphiql-explorer-action-button {
  background-color: transparent;
  border: none;
  color: hsla(var(--color-neutral), var(--alpha-secondary, 0.6));
  cursor: pointer;
  font-size: 1em;
  padding: 0;
  margin: 0;
  max-width: none;
  height: 15px;
  width: 15px;
  display: inline-block;
}

.graphiql-explorer-actions {
  padding: var(--px-8) var(--px-4);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid rgb(214, 214, 214);
  margin: 4px -8px -8px;
  padding-left: 8px;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: none;
  border-top: none;
  border-bottom: none;
}

.graphiql-explorer-root select {
  background-color: hsl(var(--color-base));
  border: 1px solid hsla(var(--color-neutral), var(--alpha-secondary));
  border-radius: var(--border-radius-4);
  color: hsl(var(--color-neutral));
  margin: 0 var(--px-4);
  padding: var(--px-4) var(--px-6);
}
