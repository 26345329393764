.fixed-header-style{
  position:fixed !important;
  top:62px !important;
  z-index: 1000 !important;  
  width: 94%;
}

.ag-theme-file-loadmonitor-light {
  --ag-header-background-color: #6B5ECC;
  --ag-header-cell-label: "white"
}

.ag-theme-file-loadmonitor-light .ag-header-cell-label {
  color: white !important;
}

.ag-theme-file-loadmonitor-legacy {
  --ag-header-background-color: #242E4E;
}

.ag-theme-file-loadmonitor-legacy .ag-header-cell-label {
  color: white !important;
}

.ag-theme-file-loadmonitor-dark {
  --ag-header-background-color: #30455c;
}

.ag-theme-file-loadmonitor-dark .ag-header-cell-label {
  color: white !important;
}

@media screen  and (min-width:2000px){
  .fixed-header-style{
    position:fixed !important;
    top:62px !important;
    z-index: 1000 !important;  
    width: min-content !important;
  }
}
