.ag-theme-status-table-light {
  --ag-header-height: 50px;
  --ag-header-foreground-color: #97a9bc;
  --ag-header-background-color: #6B5ECC;
}

.ag-theme-status-table-dark {
  --ag-header-height: 50px;
  --ag-header-foreground-color: #97a9bc;
  --ag-header-background-color: #242E4E;
}

.ag-theme-status-table-legacy {
  --ag-header-height: 50px;
  --ag-header-foreground-color: #97a9bc;
  --ag-header-background-color: #30455c;
}

.ag-theme-status-table-light .ag-header-cell {
  font-size: 15px;
  row-gap: 24px;
}

.ag-theme-status-table-dark .ag-header-cell {
  font-size: 15px;
  row-gap: 24px;
}

.ag-theme-status-table-legacy .ag-header-cell {
  font-size: 15px;
  row-gap: 24px;
}